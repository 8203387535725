import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Helmet } from 'react-helmet'
import styles from '../pages/reviews.module.css'
import Layout from '../components/layout'
import ArticlePreview from '../components/lender-preview'
import '../assets/main.css'
import SideContent from '../components/side-components/article-side.js'
import { Link } from "gatsby";

class BlogIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const posts = get(this, 'props.data.allContentfulLender.edges')
    const articles = get(this, 'props.data.allContentfulArticle.edges')

    const { currentPage, numPagesLender } = this.props.pageContext

    var url = this.props.location.pathname;
    var parsed = url.split("/");
    var x = parseInt(parsed[2]);
    this.x = parseInt(parsed[2]);
    console.log(x);
    console.log(this.x);

    if(isNaN(this.x)){
     this.x = 1;
	}

    const mainContent = (
         <div>
            {/*<h2 className="section-headline text-center">Recent Reviews</h2>*/}
            <div className="px-2 ">
              {posts.map(({ node }) => {
                return (
                    
                    <ArticlePreview article={node} className=" -mx-10" />
                 
                )
              })}
              <div className="justify-center text-center p-4">
                <div className="flex justify-center items-center">
                {Array.from({ length: numPagesLender }, (_, i) => (
                 <div>
                           
                            {this.x == i+1 ?
                                <div className="p-2">
                                    <Link className="" key={`pagination-number${i + 1}`} to={`/articles/${i === 0 ? "" : i + 1}`}>
                                        <div className=" font-bold text-2xl">{i + 1}</div>
                                    </Link>
                                </div>
                    :
                <div className="p-2">
                <Link className="no-underline text-xl" key={`pagination-number${i + 1}`} to={`/reviews/${i === 0 ? "" : i + 1}`}>
                
                    <div className="no-underline text-md ">{i + 1}</div>
                
                </Link>
             
                </div>
                
                }
                </div>
                ))}
                </div>
               </div>
          </div>
        </div>
    
    );

    return (
      <Layout location={this.props.location} apply={""} heading={"Related Articles"} mainContent={mainContent} title={"Lender Reviews"} apply={""} image={require("../assets/img/4.jpg")} sideContent={<SideContent articles={articles} />}>
       
          <Helmet title={siteTitle} />
     
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query BlogIndexQuery ($skip: Int!, $limit: Int!){
    
    allContentfulArticle(limit: 8){
      edges {
        node {
          title
          description {
            description
          }
          publishDate(formatString: "MMMM Do, YYYY")
          slug
          image {
            file {
                url
            }
}
        }
      }
    }
    
    allContentfulLender(sort: { fields: [publishDate], order: DESC }
    limit: $limit
    skip: $skip) {
      edges {
        node {
          title
          publishDate
          description {
            description
          }
          slug
          image {
            file {
                url
            }
         }
        }
      }
    }
  }
`
